import { ref } from 'vue';

const commentsList = ref([]);
const completeCommentList = ref([]);
const isCommentsListLoading = ref(false);

export default () => {
    const filterCommentsList = (keyword) => {
        if (!keyword || !completeCommentList.value) {
            commentsList.value = completeCommentList.value;
        } else {
            commentsList.value = completeCommentList?.value.filter(key =>
            key.description?.toLowerCase().includes(keyword.toLowerCase()) ||
            key.submissionStageKeyName?.toLowerCase().includes(keyword.toLowerCase()) ||
            key.submissionStepKeyName?.toLowerCase().includes(keyword.toLowerCase())
            );
        }
    };
    const setCommentsLists = (comments) => {
        commentsList.value = comments ? [...comments] : [];
        completeCommentList.value = comments ? [...comments] : [];
    };

    return {
        filterCommentsList,
        completeCommentList,
        commentsList,
        setCommentsLists,
        isCommentsListLoading
    };
};
