<template>
  <collapse-card
    v-bind="$attrs"
    :model-value="true"
  >
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Collaboration Comments
      </span>
    </template>
    <template #cardContent>
      <div class="py-2 px-4">
        <collapse-card
          v-if="!isNewCollaboration"
          :model-value="true"
        >
          <template #title>
            <span class="mr-2 text-sm font-medium">
              Comments History
            </span>
          </template>
          <template #cardContent>
            <div class="py-2 px-4 max-h-144 overflow-y-auto">
              <brand-assurance-comments
                :disable-header="true"
              />
            </div>
          </template>
        </collapse-card>
        <base-input
          v-if="isCollaborationActive"
          v-model="collaborationForm.comment.description"
          text-area
          rows="3"
          container-class="mt-4 mb-4 flex"
          label-class="w-32 mr-4 mt-4"
          label="Add Comment"
          :error="!collaborationForm.comment.description"
          :error-text="`Enter a comment to Submit the form`"
        />
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { computed, ref, defineAsyncComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import useComments from '@/components/brand-assurance-comments/comments.js';
import useCollaborationForm from './collaborationForm.js';

export default {
    name: 'CollaborationComments',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BrandAssuranceComments: defineAsyncComponent(() => import('@/components/brand-assurance-comments/BrandAssuranceComments.vue'))
    },

    inheritAttrs: false,

    setup () {
        const store = useStore();
        const { collaborationForm, isCollaborationActive, isNewCollaboration } = useCollaborationForm();
        const { setCommentsLists, isCommentsListLoading } = useComments();

        // comments modal logic
        const showCommentsModal = ref(false);
        const setCommentsModalVisibility = (modalVisibility) => {
            showCommentsModal.value = modalVisibility;
        };
        const commentsModalTitle = ref('');

        // fetch comments logic
        const collaborationComments = computed(() => store.getters['baCollaborations/getCollaborationCommentsList']);

        onMounted(async () => {
            try {
                if (isCommentsListLoading.value) {
                    return;
                }
                setCommentsModalVisibility(true);
                isCommentsListLoading.value = true;
                await store.dispatch('baCollaborations/fetchCollaborationComments', {
                    params: {
                        submissionId: collaborationForm.collaborationId
                    }
                });
                setCommentsLists(collaborationComments.value);
            } catch (err) {
                console.error(err);
            } finally {
                isCommentsListLoading.value = false;
            }
        });

        return {
            collaborationForm,
            isCollaborationActive,
            isNewCollaboration,
            showCommentsModal,
            setCommentsModalVisibility,
            commentsModalTitle
        };
    }
};
</script>
